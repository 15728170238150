import Layout from '@components/Layout'
import { NextPageContext } from 'next'

export default function Home({ config, path, reqCookie }) {
    return <Layout config={config} path={path} reqCookie={reqCookie} />
}

Home.getInitialProps = async function ({ query, asPath, req }: NextPageContext) {
    if (!query.pageJson) throw new Error(`ROOT_PAGE_JSON_NOT_FOUND: ${query.title}`)
    return {
        config: JSON.parse(query.pageJson as string),
        path: asPath,
        reqCookie: req?.headers.cookie,
    }
}
